import { makeAutoObservable } from 'mobx';
import {getRequest} from "utils/axios";

const defaultUserStoreDate = {
    userInfo: {},
    userList: []
}
class Store {
    userInfo: any;
    userList: any[];

    constructor(userStore: { userInfo: any; userList: any[] }) {
        makeAutoObservable(
            this,
            {

            },
            {
                autoBind:true
            }
        );
        this.userInfo = userStore.userInfo
        this.userList = userStore.userList
    }

    async fetchGetUserInfo() {
        return getRequest("http://localhost:8081/users/getUserInfo", {}).then((res: any) => {
            if (res?.code === "0") {
                this.userInfo = res?.data;
                return res?.data
            }
        }).catch((e) => {
            console.log('e', e)
        })
    }

    async fetchGetUserList() {
        return getRequest("http://localhost:8081/users/getUserList", {}).then((res: any) => {
            if (res?.code === "0") {
                this.userList = res?.data?.userList || [];
                return res?.data?.userList || []
            }
        }).catch((e) => {
            console.log('e', e)
        })
    }

}

const userStore: Store = new Store(defaultUserStoreDate);

export default userStore;