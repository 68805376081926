import React from "react";
import {MagicTablePropsInterface} from "utils/interface/MagicComponentsInterface";
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import './magic-table.style.scss'

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}
const MagicTable: React.FC<MagicTablePropsInterface> = (props: MagicTablePropsInterface) => {
    const {data} = props
    const columns: TableColumnsType<DataType> = [
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '密码',
            dataIndex: 'passwd',
            key: 'passwd'
        },
    ];

    return (
        <div className={"app-magic-table-content-main"}>
            <Table
                bordered={true}
                columns={columns}
                dataSource={data}
                key={"key"}
                rowKey={record => record?.id}
            />
        </div>
    )
}

export default MagicTable