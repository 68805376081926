import axios from "axios";
import qs from "qs"
import {getToken, isLogin, requestError} from "./functions";

/*
* TODO request Get
* */
export const getRequest = (url: string, sendData: any) => {
    const authorization: string = getToken();
    return new Promise((resolve, reject) => {
        if (isLogin(url)) {
            axios.get(
                url,
                {
                    params: sendData,
                    headers: {
                        Authorization: authorization
                    }
                }
            ).then((res) => {
                const responseCode = res?.data?.code;
                if (responseCode === "0") {
                    resolve(res.data);
                } else if (responseCode === "TOKEN_INVALID") {
                    localStorage.removeItem("access_token");
                }
            }).catch((error) => {
                const {response} = error;
                const responseCode = response?.data?.code;
                const responseMessage = response?.data?.message;
                if (responseCode === 500 && responseMessage) {
                    requestError(responseMessage);
                }
            })
        } else {
            reject("请先登录")
        }
    })
}

/*
* TODO request Post
* */
export const postRequest = (url: string, sendData: any) => {
    const authorization: string = getToken();
    return new Promise((resolve, reject) => {
        if (isLogin(url)) {
            axios.post(
                url,
                qs.stringify(sendData),
                {
                    headers: {
                        Authorization: authorization
                    },
                    withCredentials: true,
                }
            ).then((res) => {
                const responseCode = res?.data?.code;
                if (responseCode === "0") {
                    resolve(res.data);
                } else if (responseCode === "TOKEN_INVALID") {
                    localStorage.removeItem("access_token");
                }
            }).catch((error) => {
                const {response} = error;
                const responseCode = response?.data?.code;
                const responseMessage = response?.data?.message;
                if (responseCode === 500 && responseMessage) {
                    requestError(responseMessage);
                }
            })
        } else {
            reject("请先登录")
        }
    })
}