import React from 'react';
import { observer } from 'mobx-react-lite';
import {mobxStore} from "utils/mobx";

const InternalHome:React.FC = observer(() => {
    const { count, increment, decrement } = mobxStore.loginStore;

    return (
        <div>
            <h2>Counter: {count}</h2>
            <button onClick={increment}>Increment</button>
            <button onClick={decrement}>Decrement</button>
        </div>
    );
});

export default InternalHome;