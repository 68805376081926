import React, {useEffect, useState} from 'react'
import {Flex } from 'antd';
import { Layout, Menu } from 'antd';
import './magic-sider.style.scss'
import {observer} from "mobx-react-lite";
import internalMenuStore from "utils/mobx/internalMenuStore";
import {toJS} from "mobx";
import {processMenuItems} from "../../utils/functions";
const { Sider } = Layout;


const MagicSider: React.FC = observer(() => {
    const {internalMenuList} = internalMenuStore
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [internalMenuData, setInternalMenuData] = useState<any[]>([])

    useEffect(() => {
        const menuItems = processMenuItems(toJS(internalMenuList), "0")
        setInternalMenuData(menuItems)
    }, [internalMenuList])

    return (
        <span
            className={"magic-sider-content-main"}
            style={{width: `${collapsed ? 80 : 200}px`}}
        >
            <Sider
                className={'magic-side-content'}
                collapsible
                collapsed={collapsed}
                onCollapse={(value: boolean) => setCollapsed(value)}
            >
                <div className={`magic-side-header ${collapsed && 'magic-side-header-collapsed'}`}>
                    <Flex
                        className={"magic-side-header-content"}
                        justify={"center"}
                        align={"center"}
                    >
                        iHiter
                    </Flex>
                </div>
                <div className={"magic-sider-menu-content"}>
                    <div className={"scroll"}>
                        <Menu
                            theme="dark"
                            defaultSelectedKeys={['1']}
                            mode="inline"
                            items={internalMenuData}
                        />
                    </div>
                </div>
            </Sider>
        </span>
    )
})

export default MagicSider;