import React, {useEffect, useState} from 'react';
import ReactRouter from "router";
import {observer} from "mobx-react-lite";
import {Location, useLocation} from 'react-router-dom';
import 'pages/App.scss';
import {checkIsInternal} from "../utils/functions";
import { Layout } from 'antd';
import MagicSider from "../components/MagicSider";
const { Header, Content , Footer} = Layout;

const App:React.FC = observer(() => {
    const location:Location<any> = useLocation();
    const [showMenu, setShowMenu] = useState<boolean>(false)

    useEffect(():void => {
        const pathname: string = location.pathname
        const isInternal: boolean = checkIsInternal(pathname)
        setShowMenu(isInternal)
    }, [location.pathname])

    return (
        <div className="App">
            <Layout
                className={"app-layout"}
                style={{ minHeight: '100vh' }}
            >
                {showMenu && <MagicSider />}
                <Layout>
                    <Header style={{ padding: 0, background: "#fff" }} />
                    <Content
                        className={"app-content"}
                        style={{  }}
                    >
                        <ReactRouter />
                    </Content>
                    <Footer style={{ textAlign: 'center', background: "#fff" }}>
                        iHiter Home ©{new Date().getFullYear()} Created by iHiter Technology <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2024020479号-1</a>
                    </Footer>
                </Layout>
            </Layout>
        </div>
    );
})

export default App;
