import React from "react";
import {Routes, Route} from "react-router-dom";
import InternalHome from "pages/internal/home";
import Login from "pages/others/login/login";
import UserSetting from "pages/internal/system/userSetting";

const ReactRouter: React.FC = () => {
     return(
         <Routes>
             <Route path="/" element={<div>home</div>} />
             <Route path="/login" element={<Login />} />
             <Route path="/404" element={<div>NotFound</div>} />
             <Route path="/home" element={<div>123</div>} />
             <Route path="/internal/home" element={<InternalHome />} />
             <Route path="/internal/userSetting" element={<UserSetting />} />
             <Route path="/admin" element={<UserSetting />} />
         </Routes>
     )
}

export default ReactRouter;