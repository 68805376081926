import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import { Button, Checkbox, Form, type FormProps, Input } from 'antd';
import {mobxStore} from "utils/mobx";
import './login.style.scss'
import {useNavigate} from "react-router-dom";
import {ROUTER_PATH_INTERNAL_HOME} from "utils/constants";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const Login: React.FC = observer(() => {
    const navigate = useNavigate();
    const {loginStore, userStore} = mobxStore
    const [title, setTitle] = useState<"登录" | "注册">("登录")

    const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
        const userLogin = await loginStore.fetchLogin(values)
        if (userLogin?.code) {
            const userInfo = await userStore.fetchGetUserInfo()
            if (userInfo?.userName) {
                navigate(ROUTER_PATH_INTERNAL_HOME)
            }
        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div className={"application-login-content"}>
        <div className={"application-login-content-main"}>
            <div className={"application-login-title"}>
                {title}
            </div>
            <div className={"application-login-proof"}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        label="UserName"
                        name="userName"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Passwd"
                        name="passwd"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{ offset: 8, span: 16 }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>
})

export default Login