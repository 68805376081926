import { makeAutoObservable } from 'mobx';
import {LoginReq} from "../../interface/loginInterface";
import {postRequest} from "../../axios";

const defaultLoginStoreDate = {
    count: 0
}
class Store {
    count: number;

    constructor(loginStore: { count: number; }) {
        makeAutoObservable(
            this,
            {

            },
            {
                autoBind:true
            }
        );
        this.count = loginStore.count
    }

    increment(): void {
        this.count++;
    }

    decrement(): void {
        this.count--;
    }

    async fetchLogin(req: LoginReq) {
        return postRequest("http://localhost:8081/users/login", req).then((res: any) => {
            if (res?.code === "0") {
                localStorage.setItem("access_token", res?.data?.code)
                return res?.data
            }
        }).catch((e) => {
            console.log('e', e)
        })
    }
}

const loginStore = new Store(defaultLoginStoreDate);

export default loginStore;