import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {mobxStore} from "../../../../utils/mobx";
import {toJS} from "mobx";
import MagicTable from "../../../../components/MagicTable";
import MagicForm from "../../../../components/MagicForm";

const UserSetting: React.FC = observer(() => {
    const {userStore} = mobxStore
    const {fetchGetUserList, userList} = userStore

    useEffect(() => {
        fetchGetUserList()
    }, [])

    return (
        <div className={"internal-user-setting-content"}>
            <div className={"internal-user-setting-title"}>
                用户设置
            </div>
            <div className={"internal-user-setting-search-content"}>
                <MagicForm />
            </div>
            <div className={"internal-user-setting-table-content"}>
                <MagicTable
                    data={toJS(userList)}
                />
            </div>
        </div>
    )
})
export default UserSetting;