import { makeAutoObservable } from 'mobx';
import {InternalMenuItem, InternalMenuStoreInterface} from "../../interface/internalMenuInterface";

const defaultInternalMenuStoreDate: InternalMenuStoreInterface = {
    internalMenuList: [
        {
            id: '1',
            type: 'static',
            parentId: '0',
            icon: 1,
            label: '首页',
            url: '/internal/home',
            magicId: '',
            sort: "0"
        },
        {
            id: '2',
            type: 'static',
            parentId: '0',
            icon: 1,
            label: '系统设置',
            url: '',
            magicId: '',
            sort: "1"
        },
        {
            id: '3',
            type: 'static',
            parentId: '2',
            icon: 1,
            label: '系统管理',
            url: '/internal/systemConfig',
            magicId: '',
            sort: "0"
        },
        {
            id: '4',
            type: 'static',
            parentId: '2',
            icon: 1,
            label: '人员管理',
            url: '/internal/personalConfig',
            magicId: '',
            sort: "0"
        },
    ]
}
class Store {
    internalMenuList: InternalMenuItem[];

    constructor(internalMenuStore: InternalMenuStoreInterface) {
        makeAutoObservable(
            this,
            {

            },
            {
                autoBind:true
            }
        );
        this.internalMenuList = internalMenuStore.internalMenuList
    }

}

const internalMenuStore = new Store(defaultInternalMenuStoreDate);

export default internalMenuStore;