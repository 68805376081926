import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Form, Row, Col } from 'antd';
import './magic-form.style.scss'
import MagicInput from "../MagicFormItem/MagicInput";
const MagicForm: React.FC = () => {
    const [form] = Form.useForm();
    const [clientReady, setClientReady] = useState<boolean>(false);

    useEffect(() => {
        setClientReady(true);
    }, []);

    const onFinish = (values: any) => {
        console.log('Finish:', values);
    };

    const formItemList = [
        {
            name: "姓名",
            key: 'name',
            type: "input"
        },
        {
            name: "年龄",
            key: 'age',
            type: "input"
        },
        {
            name: "年龄2",
            key: 'age2',
            type: "input"
        },
        {
            name: "年龄3",
            key: 'age4',
            type: "input"
        },
        {
            name: "年龄4",
            key: 'age4',
            type: "input"
        },
    ]

    const getFormItems = () => {
        const formItems:any[] = formItemList.map((v: any, index: number) => {
            switch (v?.type) {
                case "input":
                    return (
                        <Col
                            className="gutter-row magic-form-gutter-row"
                            span={6}
                        >
                            <div className={"magic-form-item-label"}>
                                {v?.name}:
                            </div>
                            <Form.Item
                                key={`${v?.key}-${index}`}
                                name={v?.key}
                                // label={v?.name}
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <MagicInput />
                                {/*<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />*/}
                            </Form.Item>
                        </Col>
                    )
                break;
                default:
                    break;
            }
        })
        return formItems;
    }

    return (
        <div className={"app-magic-form-content-main"}>
            <Form
                form={form}
                name="horizontal_login"
                className={"magic-form-main"}
                layout="inline"
                onFinish={onFinish}
            >
                <div className={"magic-form-item-content"}>
                    <Row gutter={16}>
                        {getFormItems()}
                    </Row>
                </div>
                <div className={"magic-form-button-content"}>
                    <Form.Item shouldUpdate>
                        {() => (
                            <div className={"magic-form-button-content-main"}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !clientReady ||
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    搜索
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !clientReady ||
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >
                                    重置
                                </Button>
                                <div className={"magic-form-icon"}>
                                    <DownOutlined />
                                </div>
                            </div>
                        )}
                    </Form.Item>
                </div>

            </Form>
        </div>
    )
}

export default MagicForm