import {ROUTER_PATH_INTERNAL, ROUTER_PATH_LOGIN} from "./constants";
import {InternalMenuItem} from "./interface/internalMenuInterface";

/*
* TODO 获取用户Token
*  */
export const getToken = () => {
    return (localStorage.getItem('access_token') || '');
}

/*
* TODO 判断是否是login
* */
export const isLogin = (url: string) => {
    const token: String = getToken();
    const isFormLogin = url.includes('login');
    if (!token && !isFormLogin) {
        window.location.replace(ROUTER_PATH_LOGIN)
    }
    return !!token || isFormLogin;
}

/*
* TODO request error
* */
export const requestError = (responseError: string) => {
    switch (responseError) {
        default:
            break;
    }
}

export const checkIsInternal = (url: string):boolean => {
    return url.indexOf(ROUTER_PATH_INTERNAL) > -1;
}

export const processMenuItems = (data: InternalMenuItem[], id: string) => {
    const items: any[] = []
    const parentIds: string[] = []
    let children: any = {}
    if (data.length > 0 && id === "0") {
        data.forEach((v: InternalMenuItem, index: number) => {
            if (v.parentId === "0") {
                const item = {
                    id: v?.id,
                    label: v?.label,
                    url: v?.url,
                    key: v?.id,
                    onClick: () => {
                        console.log('v', v)
                    }
                }
                items.push(item)
            } else {
                if (parentIds.indexOf(v?.parentId) > -1) {
                    children[v?.parentId] = [...children[v?.parentId], v];
                } else {
                    parentIds.push(v?.parentId)
                    children = {
                        ...children,
                        [v?.parentId]: [v]
                    }
                }
            }
        })
    } else if (data.length > 0) {
        data.forEach((v: InternalMenuItem, index: number) => {
            const item = {
                id: v?.id,
                label: v?.label,
                url: v?.url,
                key: v?.id,
                onClick: () => {
                    console.log('v', v)
                }
            }
            items.push(item)
        })
    }

    if (parentIds.length > 0) {
        parentIds.forEach((v: string, index: number) => {
            const childrenItems = processMenuItems(children[v], v)
            const fatherIndex: number = items.findIndex((fatherItem) => fatherItem?.id === v)
            const newItem = {
                ...items[fatherIndex],
                children: childrenItems
            }
            items.splice(fatherIndex, 1, newItem)
        })
    }

    return items
}